import { HighchartsReact } from 'highcharts-react-official'
import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import axios from 'axios';
import { serverUrl } from 'global/variable';
import userStore from 'store/userStore';
import Swal from 'sweetalert2';
import { CustomDescrypt } from 'global/func';

require("highcharts/modules/map")(Highcharts);

const MapChartDrilldown = ({ kecamatans = [] }) => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)
    const [data, setData] = useState([]);
    // let [mapData, setMapData] = useState([]);
    const [options, setOptions] = useState({})
    // const [kelurahans, setKelurahans] = useState([])


    useEffect(() => {
        let dtx = []
        kecamatans.forEach((kecamatan, index) => {
            // let shp = map.find(item => item.id === kecamatan.id_kec)

            let dt = {
                id: kecamatan.id_kec,
                value: kecamatan.perolehan,
                dpt: kecamatan.dpt,
                name: kecamatan.nama_kec,
                drilldown: kecamatan.id_kec,
                path: kecamatan.path
            }
            dtx[index] = dt;
        });
        setData(dtx)
        // console.log(dtx);
        // console.log(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kecamatans])

    useEffect(() => {
        setOptions({
            chart: {
                events: {
                    drilldown: function (e) {
                        if (!e.seriesOptions) {
                            // console.log(e);
                            var chart = this

                            // Show the spinner
                            chart.showLoading('Memuat Data'); // Font Awesome spinner

                            axios.get(serverUrl + '/kecamatan/perolehan/' + e.point.id,
                                {
                                    headers: {
                                        'Authorization': `Bearer ${decryptedUser}`,
                                        'content-type': 'multipart/form-data'
                                    }
                                })
                                .then((response) => {
                                    console.log(response);
                                    let dtx = []
                                    let kelurahans = response.data;
                                    // let kels = mapKel[e.point.id]
                                    kelurahans.forEach((kel, index) => {
                                        // let shp = kels.find(item => item.id === kel.id_kel)

                                        let dt = {
                                            id: kel.id_kel,
                                            value: kel.perolehan,
                                            dpt: kel.dpt,
                                            name: kel.nama_kel,
                                            path: kel.path
                                        }
                                        dtx[index] = dt;
                                    });
                                    // setData(dtx)
                                    console.log(dtx);
                                    chart.hideLoading();
                                    clearTimeout();
                                    chart.addSeriesAsDrilldown(e.point, {
                                        name: e.point.name,
                                        animation: true,
                                        data: dtx,
                                        borderColor: 'black',
                                        borderWidth: 0.2,
                                        dataLabels: {
                                            enabled: true,
                                            format: "{point.name}"
                                        }
                                    });
                                })
                                .catch((error) => {
                                    chart.showLoading(
                                        'Gagal memuat Data ' + e.point.name
                                    );
                                    chart.hideLoading();
                                    // console.log(error);
                                    if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                                        logOut();
                                    }
                                    else {
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: error.response.status + ' ' + error.response.statusText
                                        })
                                    }
                                })
                        }

                        this.setTitle(null, { text: e.point.name });
                    },
                    drillup: function () {
                        this.setTitle(null, { text: "" });
                    },
                },
                // animation: false
            },

            title: {
                text: 'Perolehan Suara per Kecamatan'
            },

            subtitle: {
                text: 'Kota Baubau'
            },

            credits: {
                enabled: false
            },

            // legend: {
            //     title: {
            //         text: 'Population density per km²'
            //     }
            // },

            mapNavigation: {
                enabled: true,
                buttonOptions: {
                    verticalAlign: 'bottom'
                },
                enableMouseWheelZoom: false
            },

            colorAxis: {
                min: 0,
                // minColor: "#E6E7E8",
                // maxColor: "#005645"
            },

            plotOptions: {
                map: {
                    dataLabels: {
                        enabled: true,
                        useHtml: true,
                        // format: '{point.name}'
                        formatter: function () {
                            return (
                                `<p>${this.point.name}</p>`
                            )
                        }
                    }
                },
                series: {
                    animation: false
                },
            },

            tooltip: {
                useHtml: true,
                // pointFormat: '{point.y:,.0f}',
                formatter: function () {
                    return (
                        `<b>${this.point.name}</b></br>
                        Perolehan ${Highcharts.numberFormat(this.point.value, 0, ',', '.')}</br>
                        DPT ${Highcharts.numberFormat(this.point.dpt, 0, ',', '.')}`
                    )
                }
            },

            series: [
                {
                    data: data,
                    animation: true,
                    name: "Kota Baubau",
                    borderColor: 'black',
                    borderWidth: 0.2,
                    dataLabels: {
                        enabled: true,
                        format: "{point.name}"
                    }
                },
                // {
                //     type: "mapline",
                //     data: separators,
                //     color: "silver",
                //     enableMouseTracking: false,
                //     animation: {
                //         duration: 500
                //     }
                // }
            ],

            drilldown: {
                activeDataLabelStyle: {
                    color: "#FFFFFF",
                    textDecoration: "none",
                    textOutline: "1px #000000"
                },
                drillUpButton: {
                    relativeTo: "spacingBox",
                    position: {
                        x: 0,
                        y: 60
                    }
                }
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])
    return (
        <HighchartsReact
            highcharts={Highcharts}
            constructorType={"mapChart"}
            options={options}
        />
    )
}

export default MapChartDrilldown