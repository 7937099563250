import axios from "axios";
import { serverUrl } from "./variable";
import Swal from "sweetalert2";
// import userStore from "store/userStore";

// const logOut = userStore((state) => state.logOut)

export const postDataWithResponse = (
    url,
    formData,
    decryptedUser,
    setLoading,
    responseData,
    logOut
) => {
    setLoading !== null && setLoading(true)
    axios.post
        (
            serverUrl + url,
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser}`,
                    'content-type': 'multipart/form-data'
                }
            }
        )
        .then((response) => {
            // console.log(response);
            responseData(response.data)
        })
        .catch((error) => {
            console.log(error);
            if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                logOut();
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.response.data
                })
            }
        })
        .finally(() => {
            setLoading !== null && setLoading(false)
        })
}

export const postDelete = (
    url,
    formData,
    decryptedUser,
    setLoading,
    afterSubmit,
    logOut
) => {
    setLoading !== null && setLoading(true)
    axios.post(
        serverUrl + url,
        formData,
        {
            headers: {
                'Authorization': `Bearer ${decryptedUser}`,
                'content-type': 'multipart/form-data'
            }
        })
        .then((response) => {
            Swal.fire('Berhasil!', response.data, 'success');
            afterSubmit()
        })
        .catch((error) => {
            console.log(error);
            if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                logOut();
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.response.data
                })
            }
        })
        .finally(() => {
            setLoading !== null && setLoading(false)
        })
}

export const postSubmit = (
    url,
    formData,
    decryptedUser,
    setLoading,
    afterSubmit,
    logOut
) => {
    setLoading !== null && setLoading(true)
    axios.post(
        serverUrl + url,
        formData,
        {
            headers: {
                'Authorization': `Bearer ${decryptedUser}`,
                'content-type': 'multipart/form-data'
            }
        })
        .then((response) => {
            console.log(response);
            afterSubmit()
            Swal.fire({
                icon: 'success',
                title: 'Berhasil!',
                text: response.data
            })
        })
        .catch((error) => {
            console.log(error);
            if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                logOut();
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.response.data
                })
            }
        })
        .finally(() => {
            setLoading !== null && setLoading(false)
        })
}