import { Clear, Done, ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Chip, Divider, Grid, List, ListItem, ListItemText } from '@mui/material'
import axios from 'axios'
import SoftTypography from 'components/SoftTypography'
import { CustomDescrypt } from 'global/func'
import { serverUrl } from 'global/variable'
import { useEffect, useState } from 'react'
import userStore from 'store/userStore'
import Swal from 'sweetalert2'

const DetailDpt = ({ data }) => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [gambar, setGambar] = useState([])

    useEffect(() => {
        reqGambar()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const reqGambar = () => {
        axios.post(
            serverUrl + '/dokumentasi-dtd',
            {
                nik: data.nik
            },
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
                setGambar(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setTableLoading(false)
            })
    }

    return (
        <List sx={{ width: '100%' }}>
            <ListItem>
                <ListItemText primary={
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        NIK
                    </SoftTypography>
                }
                    secondary={
                        <SoftTypography component="label" variant="body2">
                            {data.nik ? data.nik : '-'}
                        </SoftTypography>
                    } />
            </ListItem>
            <Divider />
            <ListItem>
                <ListItemText primary={
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        No. KK
                    </SoftTypography>
                }
                    secondary={
                        <SoftTypography component="label" variant="body2">
                            {data.no_kk ? data.no_kk : '-'}
                        </SoftTypography>
                    } />
            </ListItem>
            <Divider />
            <ListItem>
                <ListItemText primary={
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Nama
                    </SoftTypography>
                }
                    secondary={
                        <SoftTypography component="label" variant="body2">
                            {data.nama ? data.nama : '-'}
                        </SoftTypography>
                    } />
            </ListItem>
            <Divider />
            <ListItem>
                <ListItemText
                    primary={
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Tempat/Tgl Lahir
                        </SoftTypography>
                    }
                    secondary={
                        <SoftTypography component="label" variant="body2">
                            {data.tempat_lahir ? data.tempat_lahir + ', ' + data.tgl_lahir : '-'}
                        </SoftTypography>
                    }
                />
            </ListItem>
            <Divider />
            <ListItem>
                <ListItemText primary={
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Kecamatan
                    </SoftTypography>
                } secondary={
                    <SoftTypography component="label" variant="body2">
                        {data.nama_kec}
                    </SoftTypography>
                } />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
                <ListItemText primary={
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Kelurahan
                    </SoftTypography>
                } secondary={
                    <SoftTypography component="label" variant="body2">
                        {data.nama_kel}
                    </SoftTypography>
                } />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
                <ListItemText primary={
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Alamat
                    </SoftTypography>
                } secondary={
                    <SoftTypography component="label" variant="body2">
                        {data.alamat}
                    </SoftTypography>
                } />
            </ListItem>
            <Divider variant="inset" component="li" />
            {
                data.tim !== '' && (
                    <>
                        <ListItem>
                            <ListItemText primary={
                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                    Status
                                </SoftTypography>
                            } secondary={
                                <Chip label={data.tim} color='primary' />
                            } />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </>
                )
            }
            <ListItem>
                <ListItemText primary={
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        DTD
                    </SoftTypography>
                } secondary={
                    <>
                        {data.dtd_username === '' ? (<Clear fontSize='large' color='error' />) : (<Done fontSize='large' color='success' />)}
                        <SoftTypography component="label" variant="body2" sx={{display: 'block'}}>
                            {data.dtd_username !== '' && 'Terdata oleh ' + data.dtd_nama}
                        </SoftTypography>
                    </>
                } />
            </ListItem>
            <Divider variant="inset" component="li" />
            {
                data.dtd_username !== '' && (
                    <>
                        <ListItem>
                            <Grid container>
                                <Grid item lg={12}>
                                    <Accordion sx={{bgColor: 'primary'}}>
                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMore />} sx={{bgColor: 'primary'}}>
                                            <SoftTypography color="dark">Foto KTP</SoftTypography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <img
                                                src={serverUrl + '/uploads/dtd/' + data.nik + '/' + gambar[0]}
                                                alt='Dokumentasi'
                                                loading="lazy"
                                                width='100%'
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </>
                )
            }
            <ListItem>
                <ListItemText primary={
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Pertemuan Terbatas
                    </SoftTypography>
                } secondary={
                    data.pertemuan_terbatas === '' ? (<Clear fontSize='large' color='error' />) : (<Done fontSize='large' color='success' />)
                } />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
                <ListItemText primary={
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Bansos
                    </SoftTypography>
                } secondary={
                    data.bansos === '' ? (<Clear fontSize='large' color='error' />) : (<Done fontSize='large' color='success' />)
                } />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
                <ListItemText primary={
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Suara Tambahan?
                    </SoftTypography>
                } secondary={
                    <>
                        {data.suara_tambahan === '' ? (<Clear fontSize='large' color='error' />) : (<Done fontSize='large' color='success' />)}
                        <SoftTypography component="label" variant="body2" sx={{display: 'block'}}>
                            {data.suara_tambahan !== '' && 'Terdata oleh ' + data.suara_tambahan_nama}
                        </SoftTypography>
                    </>
                } />
            </ListItem>
            <Divider variant="inset" component="li" />
        </List>
    )
}

export default DetailDpt