import { Diversity1, Flag, Group, Home, Interests, Person, PersonAdd, Settings } from "@mui/icons-material";

const adminRoutes = [
    {
        type: "route",
        name: "Dashboard",
        key: [''],
        route: "/admin",
        icon: <Home size="12px" color="warning" />,
        noCollapse: true,
    },
    // {
    //     type: "route",
    //     name: "Dashboard",
    //     key: ['/'],
    //     href: "/admin",
    //     icon: <Home size="12px" />,
    //     noCollapse: true,
    // },
    {
        type: "route",
        name: "Data Pemilih",
        key: ['pemilih'],
        route: "/admin/pemilih",
        icon: <Diversity1 size="12px" color="info" />,
        noCollapse: true,
    },
    {
        type: "route",
        name: "Tim",
        key: ['tim'],
        route: "/admin/tim",
        icon: <Group size="12px" color="error" />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "Program",
        key: ['dtd', 'pt', 'bansos'],
        icon: <Interests size="12px" color="secondary" />,
        collapse: [
            {
                name: "DTD",
                key: ["/admin/dtd", "/admin/dtd/detail"],
                route: "/admin/dtd"
            },
            {
                name: "Pertemuan Terbatas",
                key: ["/admin/pt", "/admin/pt/detail"],
                route: "/admin/pt"
            },
            {
                name: "Bansos",
                key: ["/admin/bansos", "/admin/bansos/detail"],
                route: "/admin/bansos"
            },
        ],
    },
    {
        type: "route",
        name: "Suara tambahan",
        key: ['suara-tambahan'],
        route: "/admin/suara-tambahan",
        icon: <PersonAdd size="12px" color="error" />,
        noCollapse: true,
    },
    {
        type: "route",
        name: "APK",
        key: ['apk'],
        route: "/admin/apk",
        icon: <Flag size="12px" color="success" />,
        noCollapse: true,
    },
    { type: "divider", title: "Account Pages", key: "settings-divider" },
    { type: "title", title: "Settings", key: "settings" },
    {
        type: "collapse",
        name: "Manajemen User",
        key: ['vg', 'korkel', 'thi_tim'],
        icon: <Person size="12px" color="warning" />,
        collapse: [
            // {
            //     name: "Vote Getter",
            //     key: ["/admin/vg", "/admin/vg/tambah", "/admin/vg/detail"],
            //     route: "/admin/vg"
            // },
            // {
            //     name: "Relawan Brigade",
            //     key: ["/admin/korkel", "/admin/korkel/tambah", "/admin/korkel/detail"],
            //     route: "/admin/korkel"
            // },
            // {
            //     name: "Korcam Brigade",
            //     key: ["/admin/korcam", "/admin/korcam/tambah", "/admin/korcam/detail"],
            //     route: "/admin/korcam"
            // },
            // {
            //     name: "Admin SPV",
            //     key: ["/admin/thi-tim", "/admin/thi-tim/tambah", "/admin/thi-tim/detail"],
            //     route: "/admin/thi-tim"
            // },
            {
                name: "Entry Data",
                key: ["/admin/entry", "/admin/entry/tambah", "/admin/entry/detail"],
                route: "/admin/entry"
            },
            {
                name: "Admin",
                key: ["/admin/admin", "/admin/admin/tambah", "/admin/admin/detail"],
                route: "/admin/admin"
            },
        ],
    },
    {
        type: "collapse",
        name: "Pengaturan Program",
        key: ['jenis-apk', 'jenis-bantuan'],
        icon: <Settings size="12px" color="info" />,
        collapse: [
            {
                name: "Jenis APK",
                key: ["/admin/jenis-apk", "/admin/jenis-apk/tambah"],
                route: "/admin/jenis-apk"
            },
            {
                name: "Jenis Bantuan",
                key: ["/admin/jenis-bantuan", "/admin/jenis-bantuan/tambah"],
                route: "/admin/jenis-bantuan"
            }
        ],
    },
    // {
    //     type: "collapse",
    //     name: "Kecamatan",
    //     key: ['kecamatan'],
    //     route: "/admin/kecamatan",
    //     icon: <LocationOn size="12px" />,
    //     noCollapse: true,
    // },
];

export default adminRoutes;
