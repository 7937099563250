import { Card, CardContent, CardHeader, Chip, Grid, Paper, Stack, TableContainer, Typography } from '@mui/material'
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import { CustomDescrypt } from 'global/func'
import { getDataWithResponse } from 'global/getFunc'
import { MaterialReactTable } from 'material-react-table'
import React, { useEffect, useState } from 'react'
import userStore from 'store/userStore'

const Tim = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    // const [loading, setLoading] = useState(true)
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        requestData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const requestData = () => {
        getDataWithResponse(
            '/entry/tim',
            decryptedUser,
            null,
            setTableData,
            logOut
        )
    }

    const columns = [
        {
            accessorKey: 'nik',
            header: 'NIK',
        },
        {
            accessorKey: 'nama',
            header: 'Nama',
            Cell: ({ cell }) => {
                switch (cell.row.original.status) {
                    case 'Korcam':
                        return <Chip label={cell.row.original.nama} color='primary' />

                    case 'Relawan Brigade':
                        return <Chip label={cell.row.original.nama} color='success' />

                    default:
                        return <Chip label={cell.row.original.nama} color='warning' />
                }
            }
        },
        {
            accessorKey: 'id_kec',
            header: 'ID_KEC'
        },
        {
            accessorKey: 'nama_kec',
            header: 'Kec',
        },
        {
            accessorKey: 'id_kel',
            header: 'ID_KEL'
        },
        {
            accessorKey: 'nama_kel',
            header: 'Kel',
        },
        {
            accessorKey: 'no_hp',
            header: 'No. HP',
        },
        {
            accessorKey: 'status',
            header: 'Status',
        }
    ]

    const columnVisibility = {
        id_kec: false,
        id_kel: false,
    }

    return (
        <SoftBox my={3}>
            <Grid container spacing={3} justifyContent='center'>
                <Grid item xs={12} lg={12}>
                    <Card>
                        <CardHeader
                            title={
                                <SoftTypography color='text'>
                                    Tabel Tim
                                </SoftTypography>
                            }
                        />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <SoftBox mb={1} ml={0.5}>
                                        <Typography component="label" variant="body2" fontWeight="bold">
                                            Ket
                                        </Typography>
                                    </SoftBox>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} mb={2}>
                                <Grid item>
                                    <Stack direction="row" spacing={1}>
                                        <Chip label="Korcam" color="primary" />
                                        <Chip label="Korkel" color="success" />
                                        <Chip label="PIC" color="warning" />
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="space-between" >
                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <MaterialReactTable
                                            data={tableData}
                                            columns={columns}
                                            paginateExpandedRows={true}
                                            enableColumnResizing
                                            initialState={{
                                                showColumnFilters: true,
                                                columnVisibility: columnVisibility,
                                                // expanded: true,
                                                pagination: { pageSize: 20, pageIndex: 0 }
                                            }}
                                            enableExpanding={true}
                                            muiTablePaginationProps={{
                                                rowsPerPageOptions: [20, 50, 100, { label: 'All', value: tableData.length }],
                                                showFirstButton: true,
                                                showLastButton: true,
                                            }}
                                        />
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    );
}

export default Tim