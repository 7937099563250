import { GroupAddOutlined, Groups3, GroupsOutlined, People, PeopleAlt, PeopleAltOutlined, PersonAddAltOutlined } from '@mui/icons-material'
import { Card, Grid } from '@mui/material'
import axios from 'axios'
import SoftBox from 'components/SoftBox'
import { CustomDescrypt } from 'global/func'
import { serverUrl } from 'global/variable'
import InfoCard from 'myComponent/Cards/InfoCard'
import BarDrilldown from 'myComponent/Charts/BarDrilldown'
import MapChartDrilldown from 'myComponent/Charts/MapChartDrilldown'
import PieProgres from 'myComponent/Charts/PieProgres'
import VennDiagram from 'myComponent/Charts/VennDiagram'
import React, { useEffect, useState } from 'react'
import userStore from 'store/userStore'
import Swal from 'sweetalert2'
import Highcharts from 'highcharts'
import ActivityGaugeChart from 'myComponent/Charts/ActivityGaugeChart'
import BarChart from 'myComponent/Charts/BarChart'
import StackedCollumn from 'myComponent/Charts/StackedCollumn'
import SoftTypography from 'components/SoftTypography'
import { useSoftUIController } from 'context'

const Dashboard = () => {
    const [controller] = useSoftUIController();
    const { darkMode } = controller;
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [totalDpt, setTotalDpt] = useState(0)
    const [totalPemilih, setTotalPemilih] = useState(0)
    const [totalVg, setTotalVg] = useState(0)
    const [totalKorkel, setTotalKorkel] = useState(0)
    const [totalKorcam, setTotalKorcam] = useState(0)
    const [totalTim, setTotalTim] = useState(0)
    const [totalTarget, setTotalTarget] = useState(0)
    const [totalSuaraTambahan, setTotalSuaraTambahan] = useState(0)
    const [kecs, setKecs] = useState([])
    const [perolehan, setPerolehan] = useState({})
    const [perolehanDtd, setPerolehanDtd] = useState(0)
    const [perolehanBansos, setPerolehanBansos] = useState(0)
    const [perolehanPt, setPerolehanPt] = useState(0)

    const [stackColCategories, setStackColCategories] = useState([])
    const [stackColSeries, setStackColSeries] = useState([])

    useEffect(() => {
        // reqDataPendataan()
        // getDataPieChartBpjs()
        // getDataStackedColumnsBpjs()
        // getDataPieChartPln()
        // getDataStackedColumnsPln()
        // getDataPieChartPdam()
        // getDataStackedColumnsPdam()
        // getDataPieChartBantuan()
        // getDataStackedColumnsBantuan()
        // getBarDrilldown()
        reqKec()
        reqPerolehan()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // let trg = 0;
        let dp = 0;
        let per = 0;
        let vg = 0;
        let korkel = 0;
        let korcam = 0;
        let tim = 0;
        let trg = 0;
        let st = 0;

        let stackCat = [];
        let arrPerProg = []
        let arrTim = []
        let arrSt = []

        kecs.forEach(data => {
            // trg = trg + data.target;
            dp += data.dpt;
            per += data.perolehan;
            vg += data.vg;
            korkel += data.korkel
            korcam += data.korcam;
            tim += data.tim
            trg += data.target
            st += data.st
            stackCat.push(data.nama_kec)
            arrPerProg.push(data.perolehan - data.tim - data.st)
            arrTim.push(data.tim)
            arrSt.push(data.st)
        });

        // setTarget(trg)
        setTotalDpt(dp)
        setTotalPemilih(per)
        setTotalVg(vg)
        setTotalKorkel(korkel)
        setTotalKorcam(korcam)
        setTotalTim(tim)
        setTotalTarget(trg)
        setTotalSuaraTambahan(st)
        // console.log(kecs);

        setStackColCategories(stackCat)
        setStackColSeries([
            {
                name: 'Tim',
                data: arrTim
            },
            {
                name: 'Perolehan Program',
                data: arrPerProg
            },
            {
                name: 'Suara Tambahan',
                data: arrSt
            }
        ])
        // console.log(stackCat);
    }, [kecs])

    const reqKec = () => {
        axios.get(
            serverUrl + '/kecamatan/perolehan',
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser}`,
                    'content-type': 'multipart/form-data'
                }
            }
        )
            .then((response) => {
                // console.log(response);
                setKecs(response.data)
                // setTableLoading(false)
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setLoading(false)
            })
    }

    const reqPerolehan = () => {
        axios.get(
            serverUrl + '/admin/perolehan-by-program',
            {
                headers: {
                    'Authorization': `Bearer ${decryptedUser}`,
                    'content-type': 'multipart/form-data'
                }
            }
        )
            .then((response) => {
                // console.log(response);
                setPerolehan(response.data)
                setPerolehanDtd(response.data[0].value)
                setPerolehanBansos(response.data[1].value)
                setPerolehanPt(response.data[2].value)
                // setTableLoading(false)
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                    logOut();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.status + ' ' + error.response.statusText
                    })
                }
                // setLoading(false)
            })
    }

    return (
        <SoftBox my={3}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} xl={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <InfoCard
                                title={{ text: "Total DPT" }}
                                icon={<PeopleAltOutlined fontSize='medium' color='warning' sx={{ opacity: 0.3, fontSize: 90 }} />}
                                count={totalDpt.toLocaleString()}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <InfoCard
                                title={{ text: "Perolehan" }}
                                icon={<GroupAddOutlined fontSize='medium' sx={{ opacity: 0.3, fontSize: 90, color: '#0097a7' }} />}
                                count={totalPemilih.toLocaleString()}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} pt={2}>
                        <Grid item xs={12} lg={4}>
                            <InfoCard
                                title={{ text: "Tim" }}
                                icon={<PersonAddAltOutlined fontSize='medium' sx={{ opacity: 0.3, fontSize: 90 }} color='error' />}
                                count={totalTim.toLocaleString()}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <InfoCard
                                title={{ text: "Perolehan Program" }}
                                icon={<PersonAddAltOutlined fontSize='medium' sx={{ opacity: 0.3, fontSize: 90 }} color='error' />}
                                count={(totalPemilih - totalTim - totalSuaraTambahan).toLocaleString()}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <InfoCard
                                title={{ text: "Suara tambahan" }}
                                icon={<PersonAddAltOutlined fontSize='medium' sx={{ opacity: 0.3, fontSize: 90 }} color='error' />}
                                count={totalSuaraTambahan.toLocaleString()}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} pt={2}>
                        <Grid item xs={12} lg={4}>
                            <InfoCard
                                title={{ text: "Korcam" }}
                                icon={<People fontSize='medium' sx={{ opacity: 0.3, fontSize: 90 }} color='disabled' />}
                                count={totalKorcam.toLocaleString()}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <InfoCard
                                title={{ text: "Relawan Brigade" }}
                                icon={<PeopleAlt fontSize='medium' sx={{ opacity: 0.3, fontSize: 90 }} color='secondary' />}
                                count={totalKorkel.toLocaleString()}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <InfoCard
                                title={{ text: "Vote Getter" }}
                                icon={<Groups3 fontSize='medium' color='info' sx={{ opacity: 0.3, fontSize: 90 }} />}
                                count={totalVg.toLocaleString()}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} pt={2}>
                        <Grid item xs={12} lg={12}>
                            <Card sx={{ p: 1 }}>
                                <MapChartDrilldown kecamatans={kecs} />
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} pt={2}>
                        <Grid item xs={12} lg={12}>
                            <Card sx={{ p: 1 }}>
                                <BarDrilldown kecamatans={kecs} />
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} pt={2}>
                        {/* <Grid item xs={12} lg={12}>
                                <InfoCard
                                    title={{ text: "Total Pemilih" }}
                                    icon={<PeopleAltOutlined fontSize='medium' color='error' sx={{ opacity: 0.3, fontSize: 90 }} />}
                                    count={(totalPemilih).toLocaleString()}
                                />
                            </Grid> */}
                        <Grid item xs={12} lg={4}>
                            <InfoCard
                                title={{ text: "Total Tim" }}
                                icon={<PeopleAltOutlined fontSize='medium' color='error' sx={{ opacity: 0.3, fontSize: 90 }} />}
                                count={(totalTim).toLocaleString()}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <InfoCard
                                title={{ text: "Total Perolehan Program" }}
                                icon={<PeopleAltOutlined fontSize='medium' color='error' sx={{ opacity: 0.3, fontSize: 90 }} />}
                                count={(totalPemilih - totalTim - totalSuaraTambahan).toLocaleString()}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <InfoCard
                                title={{ text: "Suara Tambahan" }}
                                icon={<GroupsOutlined fontSize='medium' sx={{ opacity: 0.3, fontSize: 90 }} color='warning' />}
                                count={totalSuaraTambahan.toLocaleString()}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} pt={2}>
                        <Grid item xs={12}>
                            <Card sx={{ p: 1 }}>
                                <StackedCollumn title="Perolehan Suara Per Kecamatan" categories={stackColCategories} series={stackColSeries} />
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} xl={4}>
                    <Grid
                        container
                        maxHeight={1735}
                        sx={{ overflowY: 'scroll' }}
                    // spacing={2}
                    >
                        <Grid item xs={12}>
                            <Card sx={{ p: 1 }}>
                                <Grid container spacing={0} justifyContent='center'>
                                    <Grid item sm={4} xl={12} textAlign='center'>
                                        <ActivityGaugeChart
                                            title='Kota Baubau'
                                            exporting={true}
                                            tooltip={true}
                                            data={[
                                                {
                                                    name: 'Terdata',
                                                    data: [{
                                                        color: Highcharts.getOptions().colors[0],
                                                        textColor: darkMode ? "#ffffff" : "#67748e",
                                                        radius: '100%',
                                                        innerRadius: '81%',
                                                        y: (totalPemilih / totalTarget) * 100,
                                                        angka: totalPemilih,
                                                        capt: 'Dari Target'
                                                    }]
                                                },
                                                {
                                                    name: 'Target',
                                                    data: [{
                                                        color: Highcharts.getOptions().colors[1],
                                                        textColor: darkMode ? "#ffffff" : "#67748e",
                                                        radius: '80%',
                                                        innerRadius: '61%',
                                                        // y: data[1].persen,
                                                        y: (totalTarget / totalDpt) * 100,
                                                        angka: totalTarget,
                                                        capt: 'dari total DPT'
                                                    }]
                                                }
                                            ]}
                                            background={[
                                                {
                                                    outerRadius: '100%',
                                                    innerRadius: '81%',
                                                    backgroundColor: Highcharts.color(Highcharts.getOptions().colors[0])
                                                        .setOpacity(0.3)
                                                        .get(),
                                                    borderWidth: 0
                                                },
                                                {
                                                    outerRadius: '80%',
                                                    innerRadius: '61%',
                                                    backgroundColor: Highcharts.color(Highcharts.getOptions().colors[1])
                                                        .setOpacity(0.3)
                                                        .get(),
                                                    borderWidth: 0
                                                }
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0}>
                                    <Grid item xs={4} textAlign='center'>
                                        <SoftTypography variant='caption'>
                                            DPT
                                        </SoftTypography>
                                        <SoftTypography variant='h6' fontWeight='bold'>
                                            {totalDpt.toLocaleString()}
                                        </SoftTypography>
                                    </Grid>
                                    <Grid item xs={4} textAlign='center'>
                                        <SoftTypography variant='caption'>
                                            Target
                                        </SoftTypography>
                                        <SoftTypography variant='h6' fontWeight='bold'>
                                            {totalTarget.toLocaleString()}
                                        </SoftTypography>
                                    </Grid>
                                    <Grid item xs={4} textAlign='center'>
                                        <SoftTypography variant='caption'>
                                            Perolehan
                                        </SoftTypography>
                                        <SoftTypography variant='h6' fontWeight='bold'>
                                            {totalPemilih.toLocaleString()}
                                        </SoftTypography>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        {
                            kecs.map((kec) => (
                                <Grid item xs={12} key={kec.id_kec} mt={2}>
                                    <Card>
                                        <Grid container alignItems='center' justifyContent="space-around" direction="row"
                                            px={2}
                                            // component={Link} 
                                            state={kec.id_kec}
                                            to={'/admin/pendataan'}
                                        >
                                            <Grid item xs={9}>
                                                <SoftTypography
                                                    variant='h5'
                                                    fontWeight='bold'
                                                    color='text'
                                                >
                                                    {kec.nama_kec}
                                                </SoftTypography>
                                                <SoftTypography variant='body2' color='text'>
                                                    Terdata {kec.perolehan.toLocaleString()} (Target {kec.target.toLocaleString()}, total DPT {kec.dpt.toLocaleString()})
                                                </SoftTypography>
                                            </Grid>
                                            <Grid item xs={3} textAlign='center' pt={1}>
                                                <PieProgres value={(kec.perolehan / kec.target) * 100} color={kec.color} />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} pt={2}>
                <Grid item xs={12} lg={3}>
                    <InfoCard
                        title={{ text: "Total Perolehan Program" }}
                        icon={<PeopleAltOutlined fontSize='medium' color='error' sx={{ opacity: 0.3, fontSize: 90 }} />}
                        count={(totalPemilih - totalTim - totalSuaraTambahan).toLocaleString()}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <InfoCard
                        title={{ text: "DTD" }}
                        icon={<PeopleAltOutlined fontSize='medium' color='warning' sx={{ opacity: 0.3, fontSize: 90 }} />}
                        count={perolehanDtd.toLocaleString()}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <InfoCard
                        title={{ text: "Bansos" }}
                        icon={<GroupAddOutlined fontSize='medium' sx={{ opacity: 0.3, fontSize: 90, color: '#0097a7' }} />}
                        count={perolehanBansos.toLocaleString()}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <InfoCard
                        title={{ text: "Pertemuan Terbatas" }}
                        icon={<PersonAddAltOutlined fontSize='medium' sx={{ opacity: 0.3, fontSize: 90 }} color='secondary' />}
                        count={perolehanPt.toLocaleString()}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3} pt={2}>
                <Grid item xs={12} lg={12}>
                    <Card sx={{ p: 1 }}>
                        <VennDiagram seriesData={perolehan} />
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent='center' pt={2}>
                <Grid item xs={12} lg={4}>
                    <Card sx={{ height: '100%', pt: 2 }}>
                        <ActivityGaugeChart
                            title='DTD'
                            exporting={true}
                            tooltip={false}
                            data={[
                                {
                                    name: 'DTD',
                                    data: [{
                                        color: Highcharts.getOptions().colors[0],
                                        textColor: darkMode ? "#ffffff" : "#67748e",
                                        radius: '100%',
                                        innerRadius: '70%',
                                        y: (perolehanDtd / (totalPemilih - totalTim - totalSuaraTambahan)) * 100,
                                        angka: perolehanDtd,
                                        capt: 'dari Total ' + (totalPemilih - totalTim - totalSuaraTambahan) + ' Perolehan'
                                    }],
                                    dataLabels: {
                                        borderWidth: 0,
                                        padding: - 30,
                                        useHTML: true,
                                        positioner: function (labelWidth) {
                                            return {
                                                x: (this.chart.chartWidth - labelWidth) / 2,
                                                y: (this.chart.plotHeight / 2) - 40
                                            };
                                        },
                                        formatter: function () {
                                            return '<table>' +
                                                '<tr><td style="text-align: center; color: ' + this.point.textColor + '">' + this.series.name + '</td></tr>' +
                                                '<tr><td style="text-align: center; font-size:1.5rem; color: ' + this.point.color + '; font-weight: bold"">' + this.point.angka.toLocaleString() + '</td></tr>' +
                                                '<tr><td style="text-align: center; color: ' + this.point.textColor + '">(' + this.point.y.toFixed(2) + '% ' + this.point.capt + ')</td></tr>' +
                                                '</table>'
                                        }
                                    }
                                },
                            ]}
                            background={[
                                {
                                    outerRadius: '100%',
                                    innerRadius: '70%',
                                    backgroundColor: Highcharts.color(Highcharts.getOptions().colors[0])
                                        .setOpacity(0.3)
                                        .get(),
                                    borderWidth: 0
                                }
                            ]}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <InfoCard
                                title={{ text: "Total Perolehan Program" }}
                                icon={<PeopleAltOutlined fontSize='medium' color='error' sx={{ opacity: 0.3, fontSize: 90 }} />}
                                count={(totalPemilih - totalTim - totalSuaraTambahan).toLocaleString()}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <InfoCard
                                title={{ text: "DTD" }}
                                icon={<PeopleAltOutlined fontSize='medium' sx={{ opacity: 0.3, fontSize: 90 }} color='primary' />}
                                count={perolehanDtd.toLocaleString()}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} pt={2}>
                        <Grid item xs={12}>
                            <Card>
                                <BarChart kecamatans={kecs} field={'dtd'} title='DTD Per Kecamatan' />
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent='center' pt={2}>
                <Grid item xs={12} lg={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <InfoCard
                                title={{ text: "Total Perolehan Program" }}
                                icon={<PeopleAltOutlined fontSize='medium' color='error' sx={{ opacity: 0.3, fontSize: 90 }} />}
                                count={(totalPemilih - totalTim - totalSuaraTambahan).toLocaleString()}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <InfoCard
                                title={{ text: "Bansos" }}
                                icon={<GroupAddOutlined fontSize='medium' sx={{ opacity: 0.3, fontSize: 90, color: '#0097a7' }} />}
                                count={perolehanBansos.toLocaleString()}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} pt={2}>
                        <Grid item xs={12}>
                            <Card >
                                <BarChart kecamatans={kecs} field={'bansos'} title='Bansos Per Kecamatan' />
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Card sx={{ height: '100%', pt: 2 }}>
                        <ActivityGaugeChart
                            title='Bansos'
                            exporting={true}
                            tooltip={false}
                            data={[
                                {
                                    name: 'Bansos',
                                    data: [{
                                        color: Highcharts.getOptions().colors[1],
                                        textColor: darkMode ? "#ffffff" : "#67748e",
                                        radius: '100%',
                                        innerRadius: '70%',
                                        y: (perolehanBansos / (totalPemilih - totalTim - totalSuaraTambahan)) * 100,
                                        angka: perolehanBansos,
                                        capt: 'dari Total ' + (totalPemilih - totalTim - totalSuaraTambahan) + ' Perolehan'
                                    }],
                                    dataLabels: {
                                        borderWidth: 0,
                                        padding: - 30,
                                        useHTML: true,
                                        positioner: function (labelWidth) {
                                            return {
                                                x: (this.chart.chartWidth - labelWidth) / 2,
                                                y: (this.chart.plotHeight / 2) - 40
                                            };
                                        },
                                        formatter: function () {
                                            return '<table>' +
                                                '<tr><td style="text-align: center; color: ' + this.point.textColor + '">' + this.series.name + '</td></tr>' +
                                                '<tr><td style="text-align: center; font-size:1.5rem; color: ' + this.point.color + '; font-weight: bold"">' + this.point.angka.toLocaleString() + '</td></tr>' +
                                                '<tr><td style="text-align: center; color: ' + this.point.textColor + '">(' + this.point.y.toFixed(2) + '% ' + this.point.capt + ')</td></tr>' +
                                                '</table>'
                                        }
                                    }
                                },
                            ]}
                            background={[
                                {
                                    outerRadius: '100%',
                                    innerRadius: '70%',
                                    backgroundColor: Highcharts.color(Highcharts.getOptions().colors[1])
                                        .setOpacity(0.3)
                                        .get(),
                                    borderWidth: 0
                                }
                            ]}
                        />
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent='center' pt={2}>
                <Grid item xs={12} lg={4}>
                    <Card sx={{ height: '100%', pt: 2 }}>
                        <ActivityGaugeChart
                            title='Pertemuan Terbatas'
                            exporting={true}
                            tooltip={false}
                            data={[
                                {
                                    name: 'Pertemuan Terbatas',
                                    data: [{
                                        color: Highcharts.getOptions().colors[2],
                                        textColor: darkMode ? "#ffffff" : "#67748e",
                                        radius: '100%',
                                        innerRadius: '70%',
                                        y: (perolehanPt / (totalPemilih - totalTim - totalSuaraTambahan)) * 100,
                                        angka: perolehanPt,
                                        capt: 'dari Total ' + (totalPemilih - totalTim - totalSuaraTambahan) + ' Perolehan'
                                    }],
                                    dataLabels: {
                                        borderWidth: 0,
                                        padding: - 30,
                                        useHTML: true,
                                        positioner: function (labelWidth) {
                                            return {
                                                x: (this.chart.chartWidth - labelWidth) / 2,
                                                y: (this.chart.plotHeight / 2) - 40
                                            };
                                        },
                                        formatter: function () {
                                            return '<table>' +
                                                '<tr><td style="text-align: center; color: ' + this.point.textColor + '">' + this.series.name + '</td></tr>' +
                                                '<tr><td style="text-align: center; font-size:1.5rem; color: ' + this.point.color + '; font-weight: bold"">' + this.point.angka.toLocaleString() + '</td></tr>' +
                                                '<tr><td style="text-align: center; color: ' + this.point.textColor + '">(' + this.point.y.toFixed(2) + '% ' + this.point.capt + ')</td></tr>' +
                                                '</table>'
                                        }
                                    }
                                },
                            ]}
                            background={[
                                {
                                    outerRadius: '100%',
                                    innerRadius: '70%',
                                    backgroundColor: Highcharts.color(Highcharts.getOptions().colors[2])
                                        .setOpacity(0.3)
                                        .get(),
                                    borderWidth: 0
                                }
                            ]}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <InfoCard
                                title={{ text: "Total Perolehan Program" }}
                                icon={<PeopleAltOutlined fontSize='medium' color='error' sx={{ opacity: 0.3, fontSize: 90 }} />}
                                count={(totalPemilih - totalTim - totalSuaraTambahan).toLocaleString()}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <InfoCard
                                title={{ text: "Pertemuan Terbatas" }}
                                icon={<GroupsOutlined fontSize='medium' sx={{ opacity: 0.3, fontSize: 90 }} color='warning' />}
                                count={perolehanPt.toLocaleString()}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} pt={2}>
                        <Grid item xs={12}>
                            <Card>
                                <BarChart kecamatans={kecs} field={'pt'} title='Pertemuan Terbatas Per Kecamatan' />
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default Dashboard