import axios from "axios"
import { serverUrl } from "./variable"
import Swal from "sweetalert2"

export const getDataWithResponse = (
    url,
    decryptedUser,
    setLoading,
    setResponseData,
    logOut
) => {
    setLoading !== null && setLoading(true)
    axios.get(
        serverUrl + url,
        {
            headers: {
                'Authorization': `Bearer ${decryptedUser}`,
                'content-type': 'multipart/form-data'
            }
        })
        .then((response) => {
            // console.log(response);
            setResponseData(response.data)
        })
        .catch((error) => {
            console.log(error);
            if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                logOut();
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.response.status + ' ' + error.response.statusText
                })
            }
        })
        .finally(() => {
            setLoading !== null && setLoading(false)
        })
}