import { HighchartsReact } from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { useEffect, useState } from 'react';
import { useSoftUIController } from 'context';

require("highcharts/modules/exporting")(Highcharts);

const BarChart = ({ kecamatans = [], field = '', title = '' }) => {
    const [controller] = useSoftUIController();
    const { darkMode } = controller;
    const [data, setData] = useState([]);
    const [options, setOptions] = useState({})

    useEffect(() => {
        // console.log(field);
        let dtx = []
        kecamatans.forEach((kecamatan, index) => {

            let dt = {
                id: kecamatan.id_kec,
                y: kecamatan[field],
                dpt: kecamatan.dpt,
                name: kecamatan.nama_kec,
                // drilldown: kecamatan.id_kec
            }
            dtx[index] = dt;
        });
        setData(dtx)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kecamatans])

    useEffect(() => {
        setOptions({
            chart: {
                type: 'column',
                backgroundColor: darkMode ? '#111c44' : '#ffffff'
            },
            title: {
                align: 'center',
                text: title,
                style: {
                    color: darkMode ? '#ffffff' : '#67748e'
                }
                // margin: 35
            },
            subtitle: {
                align: 'center',
                // text: 'Klik kecamatan untuk melihat Data Kelurahan'
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                }
            },
            xAxis: {
                type: 'category',
                labels: {
                    style: {
                        color: darkMode ? '#ffffff' : '#67748e'
                    }
                }
            },
            yAxis: {
                title: {
                    text: 'Perolehan',
                    style: {
                        color: darkMode ? '#ffffff' : '#67748e'
                    }
                },
                gridLineWidth: 0,
                // lineColor: 'transparent',
                // minorTickLength: 0,
                // tickLength: 0
                labels: {
                    style: {
                        color: darkMode ? '#ffffff' : '#67748e'
                    }
                }
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: true,
            },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    // pointWidth: 25,
                    // borderRadius: 5,
                    dataLabels: {
                        enabled: true,
                        // format: '{point.y:.1f}%'
                        format: '{point.y}',
                    }
                }
            },

            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                // pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b>'
            },
            series: [
                {
                    name: 'Kota Kendari',
                    colorByPoint: true,
                    data: data
                },
            ],
            drilldown: {
                breadcrumbs: {
                    position: {
                        align: 'left'
                    }
                },
                activeAxisLabelStyle: {
                    textDecoration: 'none',
                    color: 'black',
                    // fontStyle: 'italic',
                    fontWeight: 'normal'
                },
                activeDataLabelStyle: {
                    textDecoration: 'none',
                    color: 'black',
                    // fontWeight: 'normal'
                },
                series: []
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data,darkMode])

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}

export default BarChart