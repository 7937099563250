import { ArrowBack, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Box, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, IconButton, List, ListItem, ListItemText, ListSubheader, MenuItem, Select, TextField, Tooltip } from '@mui/material'
import axios from 'axios'
import Headerx from 'components/Header/Headerx'
import SoftBox from 'components/SoftBox'
import SoftInput from 'components/SoftInput'
import SoftTypography from 'components/SoftTypography'
import { CustomDescrypt } from 'global/func'
import { postSubmit } from 'global/postFunc'
import { postDataWithResponse } from 'global/postFunc'
import { serverUrl } from 'global/variable'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import userStore from 'store/userStore'

const EditKorcam = () => {
    const navigate = useNavigate();
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [listDpt, setListDpts] = useState([])

    const [loading, setLoading] = useState(false)

    const [desas, setDesas] = useState([])
    const [desa, setDesa] = useState('')
    const [dpt, setDpt] = useState({})

    const location = useLocation();
    const data = location.state;

    const [formData, setFormData] = useState({
        prevNik: data.nik,
        nama: '',
        nik: '',
        no_hp: '',
        id_kec: data.id_kec,
        nama_kec: data.nama_kec
    })

    useEffect(() => {
        console.log(formData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    const reqDpt = (id_kel) => {
        postDataWithResponse(
            '/dpt',
            {
                id_kel: id_kel,
                tim: ''
            },
            decryptedUser,
            null,
            setListDpts,
            logOut
        )
    }

    const submit = (event) => {
        event.preventDefault();
        postSubmit(
            '/entry/korcam/update',
            formData,
            decryptedUser,
            setLoading,
            afterSubmit,
            logOut
        )
    }

    const afterSubmit = () => {
        navigate(-1)
    }

    const handleChangeDesa = (event) => {
        if (event.target.value !== '') {
            reqDpt(event.target.value)
            setDesa(event.target.value)
        }
    };

    useEffect(() => {
        getDesa(data.id_kec)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getDesa = async (params) => {
        const data = await axios.get(serverUrl + '/desa/' + params);
        setDesas(data.data)
        // console.log(data);
    }

    return (
        <>
            <Headerx title='Admin' />
            <SoftBox mb={3}>
                <Grid container spacing={3} justifyContent='center' py={5}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card >
                            <CardHeader
                                title={
                                    <SoftTypography variant='h6'>Kembali</SoftTypography>
                                }
                                avatar={
                                    <Tooltip title="Kembali">
                                        <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                    </Tooltip>
                                }
                            />
                            {
                                loading ?
                                    (
                                        <Grid container spacing={3} py={5} justifyContent="center">
                                            <Grid item>
                                                <Box sx={{ display: 'flex' }}>
                                                    <CircularProgress />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )
                                    :
                                    (
                                        <CardContent component='form' onSubmit={submit}>
                                            <Grid container justifyContent='center'>
                                                <Grid item xs={12} sm={12} lg={12}>
                                                    <List sx={{ bgcolor: 'background.paper' }} subheader={
                                                        <ListSubheader sx={{ px: "0" }}>
                                                            <SoftTypography component="label" variant="body2" fontWeight="bold">
                                                                Data Sebelumnya
                                                            </SoftTypography>
                                                        </ListSubheader>
                                                    }>
                                                        <ListItem>
                                                            <ListItemText primary={
                                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                    NIK
                                                                </SoftTypography>
                                                            }
                                                                secondary={
                                                                    <SoftTypography component="label" variant="body2" >
                                                                        {data.nik}
                                                                    </SoftTypography>
                                                                } />
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemText primary={
                                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                    Nama
                                                                </SoftTypography>
                                                            }
                                                                secondary={
                                                                    <SoftTypography component="label" variant="body2" >
                                                                        {data.nama}
                                                                    </SoftTypography>
                                                                } />
                                                        </ListItem>
                                                    </List>
                                                </Grid>
                                            </Grid>
                                            <Grid container justifyContent='center' direction='column' mt={3}>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftTypography component="label" variant="body2" fontWeight="bold">
                                                        Isi Data Baru
                                                    </SoftTypography>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Kecamatan
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            defaultValue={formData.nama_kec}
                                                            onChange={(event) => setFormData({ ...formData, no_hp: event.target.value })}
                                                            autoComplete='off'
                                                            required
                                                            disabled
                                                            inputProps={{ maxLength: 12 }}
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6} mb={2}>
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            Alamat
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        defaultValue=''
                                                        value={desa}
                                                        label="Desa"
                                                        onChange={handleChangeDesa}
                                                        required
                                                    >
                                                        <MenuItem value=''><em>Pilih Desa</em></MenuItem>
                                                        {
                                                            desas.map((des) => (
                                                                <MenuItem key={des.id_desa} value={des.id_desa}>{des.nama_desa}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Nama
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <Autocomplete
                                                            // disablePortal
                                                            freeSolo
                                                            id="combo-box-demo"
                                                            options={listDpt}
                                                            fullWidth
                                                            getOptionLabel={(option) => option.nik + ' ' + option.nama}
                                                            onChange={(event, newValue) => {
                                                                newValue !== null && setFormData({ ...formData, nik: newValue.nik, nama: newValue.nama })
                                                                newValue !== null && setDpt(newValue)
                                                            }}
                                                            onInputChange={(event, newInputValue) => {
                                                                newInputValue === "" && setFormData({ ...formData, nik: '', nama: '' })
                                                                newInputValue === "" && setDpt({})
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                />
                                                            )}
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                No. HP
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            defaultValue={formData.no_hp}
                                                            onChange={(event) => setFormData({ ...formData, no_hp: event.target.value })}
                                                            autoComplete='off'
                                                            required
                                                            inputProps={{ maxLength: 12 }}
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                {
                                                    formData.nik !== '' && (
                                                        <Grid item xs={12} sm={12} lg={6} >
                                                            <List sx={{ bgcolor: 'background.paper' }} subheader={
                                                                <ListSubheader sx={{px: "0"}}>
                                                                    <SoftTypography component="label" variant="body2" fontWeight="bold">
                                                                        Data Relawan
                                                                    </SoftTypography>
                                                                </ListSubheader>
                                                            }>
                                                                <ListItem>
                                                                    <ListItemText primary={
                                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                            NIK
                                                                        </SoftTypography>
                                                                    }
                                                                        secondary={
                                                                            <SoftTypography component="label" variant="body2">
                                                                                {dpt.nik ? dpt.nik : '-'}
                                                                            </SoftTypography>
                                                                        } />
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem>
                                                                    <ListItemText primary={
                                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                            No. KK
                                                                        </SoftTypography>
                                                                    }
                                                                        secondary={
                                                                            <SoftTypography component="label" variant="body2">
                                                                                {dpt.no_kk ? dpt.no_kk : '-'}
                                                                            </SoftTypography>
                                                                        } />
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem>
                                                                    <ListItemText primary={
                                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                            Nama
                                                                        </SoftTypography>
                                                                    }
                                                                        secondary={
                                                                            <SoftTypography component="label" variant="body2">
                                                                                {dpt.nama ? dpt.nama : '-'}
                                                                            </SoftTypography>
                                                                        } />
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem>
                                                                    <ListItemText
                                                                        primary={
                                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                                Tempat/Tgl Lahir
                                                                            </SoftTypography>
                                                                        }
                                                                        secondary={
                                                                            <SoftTypography component="label" variant="body2">
                                                                                {dpt.tempat_lahir ? dpt.tempat_lahir + ', ' + dpt.tgl_lahir : '-'}
                                                                            </SoftTypography>
                                                                        }
                                                                    />
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem>
                                                                    <ListItemText
                                                                        primary={
                                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                                Status Kawin
                                                                            </SoftTypography>
                                                                        }
                                                                        secondary={
                                                                            <SoftTypography component="label" variant="body2">
                                                                                { dpt.stts_kawin }
                                                                            </SoftTypography>
                                                                        }
                                                                    />
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem>
                                                                    <ListItemText
                                                                        primary={
                                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                                Jenis Kelamin
                                                                            </SoftTypography>
                                                                        }
                                                                        secondary={
                                                                            <SoftTypography component="label" variant="body2">
                                                                                {dpt.jenkel === 'L' ? 'Laki-laki' : 'Perempuan'}
                                                                            </SoftTypography>
                                                                        }
                                                                    />
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem>
                                                                    <ListItemText
                                                                        primary={
                                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                                Alamat
                                                                            </SoftTypography>
                                                                        }
                                                                        secondary={
                                                                            <SoftTypography component="label" variant="body2">
                                                                                {dpt.alamat ? `${dpt.alamat} RT. ${dpt.rt} RW. ${dpt.rw}, Kel. ${dpt.nama_kel}, Kec. ${dpt.nama_kec}, Kota. ${dpt.nama_kab}` : '-'}
                                                                            </SoftTypography>
                                                                        } />
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem>
                                                                    <ListItemText
                                                                        primary={
                                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                                RT / RW
                                                                            </SoftTypography>
                                                                        }
                                                                        secondary={
                                                                            <SoftTypography component="label" variant="body2">
                                                                                RT. {dpt.rt}, RW. {dpt.rw}
                                                                            </SoftTypography>
                                                                        } />
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem>
                                                                    <ListItemText
                                                                        primary={
                                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                                TPS
                                                                            </SoftTypography>
                                                                        }
                                                                        secondary={
                                                                            <SoftTypography component="label" variant="body2">
                                                                                {dpt.nama_tps}
                                                                            </SoftTypography>
                                                                        } />
                                                                </ListItem>
                                                                <Divider />
                                                            </List>
                                                        </Grid>
                                                    )
                                                }
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <Grid container justifyContent='flex-end'>
                                                        <Grid item>
                                                            <SoftBox mb={1} ml={0.5}>
                                                                <LoadingButton
                                                                    loading={loading}
                                                                    loadingPosition="start"
                                                                    startIcon={<Save />}
                                                                    variant="contained"
                                                                    type='submit'
                                                                >
                                                                    Save
                                                                </LoadingButton>
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    )
                            }

                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default EditKorcam