import { FormControl, MenuItem, Select } from '@mui/material'
import axios from 'axios'
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import React, { useEffect, useState } from 'react'
import { serverUrl } from '../global/variable'

const SelectDesa = ({ formData, setFormData, idKec, disabled = false, setDesass }) => {
    const [desas, setDesas] = useState([]);

    useEffect(() => {
        if (idKec === '') {
            setDesas([])
        }
        else{
            getDesa(idKec)
        }
    }, [idKec])
    
    const getDesa = async (params) => {
        const data = await axios.get(serverUrl + '/desa/' + params);
        setDesas(data.data)
        // console.log(data);
    }
    
    useEffect(() => {
        setDesass && setDesass(desas)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [desas])

    const handleChangeDesa = (event) => {
        if (event.target.value === '') {
            setFormData({ ...formData, id_kel: '', id_tps: '', nama_kel: '', nama_tps: '', dtd_username: '' })
        }
        else {
            let resultObject = desas.find(x => x.id_desa === event.target.value)
            console.log(resultObject);
            setFormData({ ...formData, id_kel: event.target.value, nama_kel: resultObject.nama_desa, id_tps: '', nama_tps: '', dtd_username: '' })
        }
    };

    return (
        <FormControl fullWidth>
            <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Pilih Kelurahan
                </SoftTypography>
            </SoftBox>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue=''
                value={formData.id_kel || ""}
                label="Desa"
                onChange={handleChangeDesa}
                required
                disabled={disabled}
            >
                <MenuItem value=''><em>Pilih Desa</em></MenuItem>
                {
                    desas.map((des) => (
                        <MenuItem key={des.id_desa} value={des.id_desa}>{des.nama_desa}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}

export default SelectDesa