import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './assets/theme';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import TemplateAdmin from 'pages/Admin/TemplateAdmin';
// import TemplateVG from 'pages/VoteGetter/TemplateVG';
// import TemplateKorkel from 'pages/Korkel/TemplateKorkel';
// import TemplateKorcam from 'pages/Korcam/TemplateKorcam';
// import TemplateTim from 'pages/ThiTim/TemplateTim';
import { useSoftUIController } from 'context';
import themeDark from 'assets/theme-dark';
import TemplateEntry from 'pages/Entry/TemplateEntry';

function App() {
    const [controller] = useSoftUIController();
    const { darkMode } = controller;

    return (
        <ThemeProvider theme={darkMode ? themeDark : theme}>
            <CssBaseline />
            <Routes>
                {/* <Route exact path='/vote-getter/*' element={<TemplateVG />} />
                <Route exact path='/korkel/*' element={<TemplateKorkel />} />
                <Route exact path='/korcam/*' element={<TemplateKorcam />} />
                <Route exact path='/thi_tim/*' element={<TemplateTim />} /> */}
                <Route exact path='/admin/*' element={<TemplateAdmin />} />
                <Route exact path='/entry/*' element={<TemplateEntry />} />
                <Route exact path='/' element={<Home />} />
            </Routes>
        </ThemeProvider>
    );
}

export default App;
