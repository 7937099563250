import { HighchartsReact } from 'highcharts-react-official'
import Highcharts from 'highcharts'
import drilldown from "highcharts/modules/drilldown.js";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { serverUrl } from 'global/variable';
import { CustomDescrypt } from 'global/func';
import userStore from 'store/userStore';
import Swal from 'sweetalert2';
import { useSoftUIController } from 'context';

require("highcharts/modules/exporting")(Highcharts);
drilldown(Highcharts)

const BarDrilldown = ({ kecamatans = [] }) => {
    const [controller] = useSoftUIController();
    const { darkMode } = controller;
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [data, setData] = useState([]);
    // let [mapData, setMapData] = useState([]);
    const [options, setOptions] = useState({})

    useEffect(() => {
        let dtx = []
        kecamatans.forEach((kecamatan, index) => {

            let dt = {
                id: kecamatan.id_kec,
                y: kecamatan.perolehan,
                dpt: kecamatan.dpt,
                name: kecamatan.nama_kec,
                drilldown: kecamatan.id_kec
            }
            dtx[index] = dt;
        });
        setData(dtx)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kecamatans])

    useEffect(() => {
        setOptions({
            chart: {
                type: 'column',
                backgroundColor: darkMode ? '#111c44' : '#ffffff',
                events: {
                    drilldown: function (e) {
                        if (!e.seriesOptions) {
                            console.log(e);
                            var chart = this

                            chart.showLoading('Memuat Data'); // Font Awesome spinner

                            axios.get(serverUrl + '/kecamatan/perolehan/' + e.point.id,
                                {
                                    headers: {
                                        'Authorization': `Bearer ${decryptedUser}`,
                                        'content-type': 'multipart/form-data'
                                    }
                                })
                                .then((response) => {
                                    console.log(response);
                                    let dtx = []
                                    let kelurahans = response.data;
                                    kelurahans.forEach((kel, index) => {

                                        let dt = [
                                            kel.nama_kel,
                                            kel.perolehan,
                                            // kel.id_kec,
                                            // kel.dpt,
                                        ]
                                        dtx[index] = dt;
                                    });
                                    // setData(dtx)
                                    console.log(dtx);
                                    chart.hideLoading();
                                    clearTimeout();
                                    chart.addSeriesAsDrilldown(e.point, {
                                        name: e.point.name,
                                        data: dtx,
                                        dataLabels: {
                                            enabled: true,
                                            format: "{point.y}"
                                        }
                                    });
                                })
                                .catch((error) => {
                                    chart.showLoading(
                                        'Gagal memuat Data ' + e.point.name
                                    );
                                    chart.hideLoading();
                                    console.log(error);
                                    if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                                        logOut();
                                    }
                                    else {
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: error.response.status + ' ' + error.response.statusText
                                        })
                                    }
                                })
                        }

                        this.setTitle(null, { text: e.point.name });
                    },
                    drillup: function () {
                        this.setTitle(null, { text: "" });
                    },
                },
            },
            // style: {
            //     color: 'red',
            // },
            title: {
                align: 'center',
                text: 'Perolehan Suara Per Kecamatan',
                style: {
                    color: darkMode ? '#ffffff' : '#67748e'
                }
                // margin: 35
            },
            subtitle: {
                align: 'center',
                text: 'Klik kecamatan untuk melihat Data Kelurahan',
                style: {
                    color: darkMode ? '#ffffff' : '#67748e'
                }
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                }
            },
            xAxis: {
                type: 'category',
                labels: {
                    style: {
                        color: darkMode ? '#ffffff' : '#67748e'
                    }
                }
            },
            yAxis: {
                title: {
                    text: 'Perolehan',
                    style: {
                        color: darkMode ? '#ffffff' : '#67748e'
                    }
                },
                gridLineWidth: 0,
                // lineColor: 'transparent',
                // minorTickLength: 0,
                // tickLength: 0
                labels: {
                    style: {
                        color: darkMode ? '#ffffff' : '#67748e'
                    }
                }
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: true,
            },
            // navigation: {
            //     buttonOptions: {
            //         align: 'right',
            //         verticalAlign: 'top',
            //         y: 0
            //     }
            // },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    // pointWidth: 25,
                    // borderRadius: 5,
                    dataLabels: {
                        enabled: true,
                        // format: '{point.y:.1f}%'
                        format: '{point.y}',
                    }
                }
            },

            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                // pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b>'
            },
            series: [
                {
                    name: 'Kota Kendari',
                    colorByPoint: true,
                    data: data
                },
            ],
            drilldown: {
                breadcrumbs: {
                    position: {
                        align: 'left'
                    }
                },
                activeAxisLabelStyle: {
                    textDecoration: 'none',
                    color: darkMode ? '#ffffff' : '#67748e',
                    // fontStyle: 'italic',
                    fontWeight: 'normal'
                },
                activeDataLabelStyle: {
                    textDecoration: 'none',
                    color: darkMode ? '#ffffff' : '#67748e'
                    // fontWeight: 'normal'
                },
                series: []
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, darkMode])

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}

export default BarDrilldown