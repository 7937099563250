import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { useEffect, useState } from 'react'
import VennModule from "highcharts/modules/venn.js";

VennModule(Highcharts);

const VennDiagram = ({ seriesData = [] }) => {
    const [options, setOptions] = useState({})
    
    useEffect(() => {
        setOptions({
            accessibility: {
                point: {
                    descriptionFormat: '{add index 1}. Intersection: {sets}. ' +
                        '{#if (gt sets.length 1)}{name}. {/if}' +
                        'Value {value}'
                }
            },
            series: [{
                type: 'venn',
                name: 'DPT terdata berdasarkan Program',
                data: seriesData
            }],
            title: {
                text: 'DPT terdata berdasarkan Program'
            },
            credits: {
                enabled: false
            }
        })
    }, [seriesData])

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}

export default VennDiagram