import { AddCircleOutline, ArrowBack } from '@mui/icons-material'
import { Button, Card, CardContent, CardHeader, Grid, IconButton, Tooltip } from '@mui/material'
import MiniStatisticsCard from 'components/Cards/StatisticsCards/MiniStatisticsCard'
import Headerx from 'components/Header/Headerx'
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import { CustomDescrypt } from 'global/func'
import { postDataWithResponse } from 'global/postFunc'
import { serverUrl } from 'global/variable'
import MyTable from 'myComponent/Table/MyTable'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import userStore from 'store/userStore'

const Detail = () => {
    const navigate = useNavigate();
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const location = useLocation();
    const data = location.state;

    const [tableData, setTableData] = useState([]);
    const [deleting, setDeleting] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        requestData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleting])

    const requestData = () => {
        postDataWithResponse(
            '/entry/suara-tambahan',
            {
                suara_tambahan: data.id
            },
            decryptedUser,
            setLoading,
            setTableData,
            logOut
        )
    }

    const columns = [
        {
            accessorKey: 'no_kk',
            header: 'No KK',
            filterVariant: 'text',
        },
        {
            accessorKey: 'nik',
            header: 'NIK'
        },
        {
            accessorKey: 'nama',
            header: 'Nama'
        },
        {
            accessorKey: 'id_kec',
            header: 'ID_KEC'
        },
        {
            accessorKey: 'nama_kec',
            header: 'Kec',
        },
        {
            accessorKey: 'id_kel',
            header: 'ID_KEL',
        },
        {
            accessorKey: 'nama_kel',
            header: 'Kel',
        },
        {
            accessorKey: 'nama_tps',
            header: 'TPS',
        },
        {
            accessorKey: 'tempat_lahir',
            header: 'Tmp Lahir'
        },
        {
            accessorKey: 'tgl_lahir',
            header: 'Tgl Lahir'
        },
    ]

    const columnVisibility = {
        no_kk: false,
        id_kec: false,
        id_kel: false,
        tempat_lahir: false,
        tgl_lahir: false,
        tim: false,
    }

    return (
        <>
            <Headerx title='Admin SPV' />
            <SoftBox my={3}>
                <Grid container spacing={3} mb={3} justifyContent='center'>
                    <Grid item xs={12} lg={12}>
                        <MiniStatisticsCard
                            title={{ text: "Total Data" }}
                            count={tableData.length}
                            // percentage={{ color: "success", text: "+55%" }}
                            icon={{ color: "info", component: "paid" }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3} justifyContent='center'>
                    <Grid item xs={12} lg={12}>
                        <Card>
                            <CardHeader
                                title={
                                    <SoftTypography color='text'>
                                        Tabel Suara Tambahan
                                    </SoftTypography>
                                }
                                avatar={
                                    <Tooltip title="Kembali">
                                        <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                    </Tooltip>
                                }
                                action={
                                    <Button
                                        variant="contained"
                                        endIcon={<AddCircleOutline color='white' />}
                                        to="/entry/suara-tambahan/tambah-st"
                                        component={Link}
                                        state={data}
                                    >
                                        Tambah
                                    </Button>
                                }
                            />
                            <CardContent>
                                <MyTable
                                    data={tableData ?? []}
                                    columns={columns}
                                    columnVisibility={columnVisibility}
                                    deleting={deleting}
                                    setDeleting={setDeleting}
                                    deleteUrl={serverUrl + '/entry/suara-tambahan/delete'}
                                    deleteField='nik'
                                    // detailUrl={'/detail'}
                                    state={{ isLoading: loading }}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default Detail